<!-- 首页文旅 -->
<template>
  <div class="home">
    <van-search
      @change="reload"
      v-model="keyword"
      show-action
      placeholder="请输入搜索关键词"
      input-align="center"
    >
      <template #label>
        <div style="display: flex;align-items: center;height: 100%;" @click="goAddress">
          <span style="font-size: 12px;">{{city}}</span>
          <van-icon name="arrow-down" />
          <div style="height: 50%;margin-left: 5px;width: 1px;background-color: #E2E1E1;"></div>
        </div>
      </template>
      <template #action>
        <div style="display: flex;align-items: center;" @click="goMap()">
          <van-icon name="location-o" />
          <span>地图</span>
        </div>
      </template>
    </van-search>

    <div class="home-top">
      <!-- <van-search v-model="value" placeholder="请输入搜索关键词" @update:model-value="onSearch(value)">
      </van-search>-->
      <van-swipe :autoplay="3000" class="home-swipe" :show-indicators="false">
        <van-swipe-item v-for="(image, index) in images" :key="index">
          <img v-lazy="image.image" />
        </van-swipe-item>
      </van-swipe>
      <div class="home-classify">
        <!-- <img :src="img.img" alt="" v-for="img in classifyImgs" /> -->
        <img src="../../../assets/hotelImage1.png" alt @click="goscenicSpotRank" />
        <img src="../../../assets/hotelImage2.png" alt @click="hotHotel" />
      </div>
    </div>
    <div class="home-middle">
      <div class="home-selected">
        <div class="home-selected-left">
          <!-- <van-swipe :autoplay="3000" class="home-middle-swipe">
						<van-swipe-item v-for="(image, index) in middleImgs" :key="index">
							<img v-lazy="image" />
						</van-swipe-item>
          </van-swipe>-->
          <div
            class="left-recommend"
            v-for="(item,index) in travels.slice(0,2)"
			:key="index"
            @click="indexDetails(item.id)"
          >
            <p class="left-recommend-title">景区</p>
            <div class="left-recommend-content">
              <img :src="item.image" alt />
              <div class="left-recommend-text">
                <span class="left-recommend-name">{{item.name}}</span>
                <div class="left-recommend-price">
                  <span>
                    ￥
                    <span class="price-number">{{item.price}}</span>
                  </span>
                  <div class="hot-icon">
                    <span>热卖中</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="home-selected-right">
          <div class="left-recommend" @click="indexDetails(hotels_top.id)">
            <p class="left-recommend-title">酒店·套餐</p>
            <div class="left-recommend-content">
              <img :src="hotels_top.image" alt />
              <div class="left-recommend-text">
                <span class="left-recommend-name">{{hotels_top.name}}</span>
                <div class="left-recommend-price">
                  <span>
                    ￥
                    <span class="price-number">{{hotels_top.price}}</span>1晚
                  </span>
                  <div class="hot-icon">
                    <span>热卖中</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="left-recommend" @click="indexDetails(hotels_bottom.id)">
            <p class="left-recommend-title">酒店·套餐</p>
            <div class="left-recommend-content">
              <img :src="hotels_bottom.image" alt />
              <div class="left-recommend-text">
                <span class="left-recommend-name">{{hotels_bottom.name}}</span>
                <div class="left-recommend-price">
                  <span>
                    ￥
                    <span class="price-number">{{hotels_bottom.price}}</span>1晚
                  </span>
                  <div class="hot-icon">
                    <span>热卖中</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="home-coupon">
        <div class="coupon-title">
          <span class="coupon-title-left">优惠好券</span>
          <span class="coupon-title-right" @click="moreCoupon">更多推荐 ></span>
        </div>
        <div class="coupon-content">
          <div class="content-list" v-for="(item,ins) in coupon" :key="ins">
            <div class="content-title">满{{Math.round(item.consumer_full_money)}}元可用</div>
            <div class="content-moeny">
              <text>{{item.consumer_reduce_money}}</text>
              <img src="../../../assets/coupon/moeny.png" alt />
            </div>
            <div class="content-get" v-if="item.is_take == 1"  @click="getTake(item)">立即领取</div>
            <div class="content-get1" v-else>立即领取</div>
          </div>
        </div>
      </div>

      <div class="home-recommend">
        <div class="recommend-title">
          <span class="recommend-title-left">景点推荐</span>

          <span class="recommend-title-right" @click="goscenicSpotRank">更多推荐 ></span>
        </div>
        <div class="recommend-content">
          <div
            class="recommend-item"
            v-for="(item, index) in hot_travels"
            :key="index"
            @click="indexDetails(item.id)"
          >
            <img :src="item.image" alt />
            <div class="recommend-item-rank">
              <span>TOP{{ index + 1 }}</span>
              <div></div>
            </div>
            <div class="recommend-item-content">
              <p class="recommend-item-title">{{ item.name }}</p>
              <!-- <div class="recommend-item-rate">
								<van-rate v-model="rateValue" :size="15" color="#FB9A00" void-icon="star"
									void-color="#eee" />
								<span>4.8分</span>
							</div>
              <span class="recommend-item-comment">15248条评论</span>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as index from "@/api/index";
import waterFall from "../../../components/waterFall.vue";
import {couponLst,couponTake} from '../../../api/coupon';
import { reactive } from "vue";
import { toUrl } from "@/utils/tools";
import { Dialog, Toast } from "vant";
export default {
  components: {
    waterFall,
  },
  data() {
    return {
      coupon:[],
      hotels: [],
      travels: [],
      hotels_bottom: "",
      hotels_top: "",
      hot_travels: [],
      active: 2,
      showIndexBar: false,
      rateValue: 3,
      value: "",
      options: "",
      nullTip: "空空如也~",
      limit: 20, // 每页条数
      page: 1, // 页码
      type: 1,
      isDownLoading: false, // 下拉刷新
      isUpLoading: false, // 上拉加载
      upFinished: false, // 上拉加载完毕
      offset: 100, // 滚动条与底部距离小于 offset 时触发load事件
      value2: 0,
      value1: 0,
      dataList: "",
      keyword: "",
      images: [],
      middleImgs: [
        "https://img0.moretickets.com/zbf/assets/img/7AzrNrJY5R_.png",
        "https://img0.moretickets.com/zbf/assets/img/HfeXZrkd6m_.png",
        "https://img0.tking.cn/mtl/default/img/YN8kbGx8tm_.jpg",
        "https://img0.moretickets.com/zbf/assets/img/NJzCMwySbf_.png",
      ],
      classifyImgs: [
        {
          img: require("../../../assets/hotelImage1.png"),
          path: "/h5/shopping",
        },
        {
          img: require("../../../assets/hotelImage2.png"),
          path: "/h5/foods",
        },
      ],
      jingxuanList: [
        {
          img: "https://b1-q.mafengwo.net/s11/M00/34/0D/wKgBEFszmbiAKKrNACs-w7hwiPA69.jpeg?imageMogr2%2Fthumbnail%2F%21296x156r%2Fgravity%2FCenter%2Fcrop%2F%21296x156%2Fquality%2F100",
          name: "洱海",
          price: 7.8,
        },
        {
          img: "https://t1-q.mafengwo.net/s11/M00/92/38/wKgBEFq3d7mAbxQpAChaezn_2m815.jpeg?imageMogr2%2Fthumbnail%2F%21380x270r%2Fgravity%2FCenter%2Fcrop%2F%21380x270%2Fquality%2F100",
          name: "沙溪古镇",
          price: 7.8,
        },
        {
          img: "https://t1-q.mafengwo.net/s7/M00/11/5D/wKgB6lT4WRCAVunpAA4hPNqqokg11.jpeg?imageMogr2%2Fthumbnail%2F%21380x270r%2Fgravity%2FCenter%2Fcrop%2F%21380x270%2Fquality%2F100",
          name: "苍山",
          price: 7.8,
        },
        {
          img: "https://b1-q.mafengwo.net/s9/M00/BD/43/wKgBs1bNpkCAS_UvAA9VGIBltqk66.jpeg?imageMogr2%2Fthumbnail%2F%21690x370r%2Fgravity%2FCenter%2Fcrop%2F%21690x370%2Fquality%2F100",
          name: "双廊古镇",
          price: 7.8,
        },
      ],
    };
  },
  computed: {
    city: function () {
      if (this.$store.state.app.city.length > 0) {
        return this.$store.state.app.city[0].name;
      } else {
        return this.$store.state.app.city.city;
      }
    },
  },
  mounted() {
    this.getHotel();
    this.couponLst();
  },
  methods: {
    couponLst() {
      let param = {
        type: "all",
        page: 1,
        limit: 4,
        is_hide: 1,
      };
      couponLst(param).then((res) => {
        console.log(res);
        this.coupon = res.data.list;
      });
    },
    getTake(item){
       let param={
        id:item.id
      }
      couponTake(param).then((res)=>{
        console.log(res)
        Toast(res.msg)
        this.couponLst();
      })
    },
    moreCoupon() {
      this.$router.push({
        path: "/h5/couponCentre",
         query: {
          type: 3,
        },
      });
    },
    // hotelPay() {
    //   this.$router.push({
    //     path: "/h5/secnicDetails",
    //   });
    // },
    goAddress() {
      this.$router.push({
        path: "/h5/address",
      });
    },
    goscenicSpotRank() {
      this.$router.push({
        path: "/h5/play/scenicSpotRank",
      });
    },
    hotHotel() {
      this.$router.push({
        path: "/h5/play/hotHotel",
      });
    },
    indexDetails(id) {
      console.log(id);
      this.$router.push({
        path: "/h5/commDetail",
        query: {
          id: id,
        },
      });
    },
    onSearch(val) {
      this.keyword = val;
      this.page = 1;
      this.getIndexList();
      document.documentElement.scrollTop = 0;
    },
    goMap() {
      this.$router.push({
        path: "/h5/map",
      });
    },

    getHotel() {
      index.getHotel().then((res) => {
        console.log(res);
        this.images = res.data.slides;
        this.hotels_top = res.data.hotels_top;
        this.hotels_bottom = res.data.hotels_bottom;
        this.travels = res.data.travels;
        this.hot_travels = res.data.hot_travels;
        console.log(res.data.hot_travels);
      });
    },
    // 上拉加载请求方法
    onLoadList() {
      this.page++;
      this.getIndexList();
    },
  },
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/preset.less";

.home {
  // overflow-y: scroll;
  // height: calc(100% - 50px);
  background-color: #f6f6f6;
  padding-bottom: 60px;

  .home-swipe {
    width: 100%;
    height: 120px;
    border-radius: 5px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .home-top {
    padding: 0 15px;
    background-color: #ffffff;
    box-sizing: border-box;
    width: 100%;

    .home-classify {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 10px 5px;
      box-sizing: border-box;

      img {
        width: 162px;
        height: 80px;
        border-radius: 5px;
      }
    }
  }

  .home-middle {
    padding: 0 15px;
    width: 100%;
    box-sizing: border-box;

    .home-selected {
      display: flex;
      padding: 10px 0;
      justify-content: space-between;

      .home-selected-right,
      .home-selected-left {
        width: 49%;
      }
      .home-selected-left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .left-recommend {
          display: flex;
          flex-direction: column;
          background-color: #feeae3;
          padding: 5px;
          box-sizing: border-box;
          border-radius: 5px;
          position: relative;

          .left-recommend-title {
            font-size: 16px;
            color: #fb9a00;
            margin: 0;
            font-weight: bold;
            margin-bottom: 5px;
          }

          .left-recommend-content {
            width: 100%;
            height: 82px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 3px;
            }

            .left-recommend-text {
              position: absolute;
              bottom: 0;
              left: 5px;
              height: 40%;
              display: flex;
              padding: 5px;
              flex-direction: column;
              justify-content: space-around;

              .left-recommend-name {
                font-size: 10px;
                color: #ffffff;
              }

              .left-recommend-price {
                display: flex;
                align-items: center;
                font-size: 10px;
                color: #ffffff;

                .price-number {
                  font-size: 16px;
                }

                .hot-icon {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: #ffffff;
                  background-color: #fc6228;
                  margin: 5px;
                }
              }
            }
          }
        }
      }
      .home-selected-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .left-recommend {
          display: flex;
          flex-direction: column;
          background-color: #feeae3;
          padding: 5px;
          box-sizing: border-box;
          border-radius: 5px;
          position: relative;

          .left-recommend-title {
            font-size: 16px;
            color: #fc6228;
            margin: 0;
            font-weight: bold;
            margin-bottom: 5px;
          }

          .left-recommend-content {
            width: 100%;
            height: 82px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 3px;
            }

            .left-recommend-text {
              position: absolute;
              bottom: 0;
              left: 5px;
              height: 40%;
              display: flex;
              padding: 5px;
              flex-direction: column;
              justify-content: space-around;

              .left-recommend-name {
                font-size: 10px;
                color: #ffffff;
              }

              .left-recommend-price {
                display: flex;
                align-items: center;
                font-size: 10px;
                color: #ffffff;

                .price-number {
                  font-size: 16px;
                }

                .hot-icon {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: #ffffff;
                  background-color: #fc6228;
                  margin: 5px;
                }
              }
            }
          }
        }
      }

      .home-selected-left {
        .home-middle-swipe {
          height: 238px;
          border-radius: 5px;

          ::v-deep .van-swipe__indicator {
            border-radius: 50%;
            width: 3px;
            height: 3px;

            background-color: #ffffff;
          }

          ::v-deep .van-swipe__indicator--active {
            border-radius: 0;
            width: 6px;
            height: 3px;

            background-color: #ffffff;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .home-coupon {
      background-color: #ffffff;
      border-radius: 5px;
      margin-top: 10px;
      padding: 5px;
      .coupon-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px;

        .coupon-title-left {
          font-size: 14px;
          font-weight: bold;
        }

        .coupon-title-right {
          font-size: 12px;
          color: #999999;
        }
      }
      //
      .coupon-content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 15px 0;
        .content-list {
          background-image: url(../../../assets/coupon/indexC.png);
          background-size: 100% auto;
          width: 75px;
          height: 90px;
          // box-shadow: 0px 1px 1px 0px rgba(65, 63, 63, 0.38);
          display: flex;
          flex-direction: column;
          align-items: center;
          .content-title {
            font-size: 12px;
            font-weight: bold;
            color: #f84214;
            padding-top: 12px;
            transform: scale(calc(10 / 12));
          }
          .content-moeny {
            font-size: 35px;
            font-weight: bold;
            color: #fb421a;
            display: flex;
            img {
              width: 9px;
              height: 8px;
            }
          }
          .content-get {
            background-image: url(../../../assets/coupon/indexJ.png);
            background-size: 100% auto;
            width: 60px;
            height: 17px;
            font-size: 12px;
            text-align: center;
            color: #fb421a;
            transform: scale(calc(10 / 12));
          }
           .content-get1 {
            background: #cecece;
            width: 60px;
            height: 17px;
            font-size: 12px;
            border-radius: 10px;
            text-align: center;
            color: #fb421a;
            transform: scale(calc(10 / 12));
          }
        }
      }
    }

    .home-recommend {
      background-color: #ffffff;
      border-radius: 5px;
      margin-top: 10px;
      padding: 5px;

      .recommend-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px;

        .recommend-title-left {
          font-size: 14px;
          font-weight: bold;
        }

        .recommend-title-right {
          font-size: 12px;
          color: #999999;
        }
      }

      .recommend-content {
        display: flex;
        width: 100%;
        margin-top: 5px;
        overflow-x: auto;
        justify-content: space-between;

        .recommend-item {
          width: 130px;
          position: relative;
          display: flex;
          flex-shrink: 0;
          flex-direction: column;
          margin-left: 5px;
          background-color: #ffffff;

          box-sizing: border-box;

          img {
            width: 100%;
            height: 85px;
            border-radius: 5px;
          }

          .recommend-item-rank {
            background-color: #ff4342;
            width: 60px;
            height: 23px;
            position: absolute;
            top: 0;
            left: 0;
            border-top-left-radius: 5px;
            border-bottom-right-radius: 20px;

            span {
              color: #ffffff;
              font-size: 14px;
              position: absolute;
              top: 3px;
              left: 5px;
            }

            div {
              height: 11px;
              width: 5px;
              position: absolute;
              right: -5px;
              top: 0;
            }

            div::after {
              content: " ";
              position: absolute;
              top: 0;
              right: 0;
              border-width: 14px 8px;
              border-style: solid;
              border-color: #ff4342 transparent transparent transparent;
            }
          }

          .recommend-item-content {
            padding: 0 5px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .recommend-item-rate {
              display: flex;
              align-items: center;
              margin-bottom: 5px;

              span {
                font-size: 10px;
                color: #fb9a00;
              }
            }

            .recommend-item-title {
              font-size: 14px;
              color: #1a1a1a;
              margin: 6px 0 3px 0;
            }

            .recommend-item-comment {
              font-size: 10px;
              color: #999999;
            }
          }
        }
      }
    }
  }
}
</style>
